
import { Address, JettonMaster } from '@ton/ton'
import showSwal from '../sweet-alerts'
import { client } from './client'

enum AssetIndexes {
  StakedTON,
  HipoStakedTON,
  Tonstakers,
  Tonkoin,
}

export const standartFee = 0.5;
export const forwardTonAmount = 0.3;

interface Assets  {
  [index: string]: string;
  readonly TON: string;
  readonly stTON: string;
  readonly hTON: string;
  readonly tsTON: string;
}
export const networkName =  () => {

  return localStorage.network && localStorage.network == 'mainnet' ? 'VUE_APP_MAINNET_' : 'VUE_APP_TESTNET_';
};

export const assets: Assets = {
  TON: 'UQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJKZ',
  stTON: process.env[networkName() + 'ST_JETTON_MINTER']!!,
  hTON:  process.env[networkName() + 'HT_JETTON_MINTER']!!,
  tsTON:  process.env[networkName() + 'TS_JETTON_MINTER']!!,
};

export const fee = 1;


const settings = {
  reservePool: 1.5,
  reserveMin: 1.5,
  burnMin: 1,
  serviceFeePercent: 0.1,
  serviceFee: 1,
  liquidationRatio: 1,
};

export const tonPrice = async () => {
  // const tonPrice = await manager.getTonPrice();
  return 0;
};


/**
 *
 * TODO: брать данные о настройках, tonPrice из текущей позиции контракта
 */

export async function timer(
  message: string,
  newVal: any,
  checkFunction: Function,
  maxAttempts: number = 30
) {
  let currentVal = await checkFunction();
  console.log(
    '============================================================================='
  );

  let attempt = 1;

  if (newVal == currentVal) {
    console.log(
      `Finished | The same value was received | ${newVal} | ${currentVal}`
    );
    console.log(
      '============================================================================='
    );
    return;
  }

  while (newVal != currentVal) {
    console.log(
      `${message}, currentVal: ${currentVal}, (attempts: ${attempt})`
    );
    await delay(10000);
    currentVal = await checkFunction();
    attempt++;
    if (maxAttempts < attempt) {
      log('Attemps limit');
      return
    }
  }
  showSwal({
    text: `${message} successfully finished`,
    icon: 'success',
  })
  console.log(`Finished`);
  console.log(
    '============================================================================='
  );
}
function log(message: String) {
  console.log(
    '\n\n============================================================================='
  );
  console.log(message);
  console.log(
    '=============================================================================\n\n'
  );
}
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function clearUserInfo() {
  localStorage.removeItem('userWallets');
  localStorage.removeItem('use');
}
export async function jettonWalletOf(minter: string, owner: string) {
  const masterInterface = JettonMaster.create(Address.parse(minter));
  const master = client.open(masterInterface);
  const jettonWalletAddress = await master.getWalletAddress(
    Address.parse(owner)
  );
  return jettonWalletAddress;
}

export type Balances = {
  TON: number;
  tsTON: number;
  hTON: number;
  stTON: number;
}
// export async function updateUserWallets(wallet: ConnectedWallet) {


//   const tsTON = await jettonWalletOf(
//     process.env[networkName() + 'TS_JETTON_MINTER']!!,
//     wallet.account.address
//   );
//   const stTON = await jettonWalletOf(
//     process.env[networkName() + 'ST_JETTON_MINTER']!!,
//     wallet.account.address
//   );
//   const hTON = await jettonWalletOf(
//     process.env[networkName() +  'HT_JETTON_MINTER']!!,
//     wallet.account.address
//   );
//   const userWallets = {
//     TON: toUserFriendlyAddress(wallet.account.address),
//     tsTON: tsTON.toString(),
//     hTON: hTON.toString(),
//     stTON: stTON.toString(),
//   }
//   localStorage.userWallets = JSON.stringify(userWallets)


// }
export function saveBalanceToLs(type: string, balances: Balances) {
  // if (type === 'wallet' ) {
  //   localStorage.balances = JSON.stringify({
  //     expireDate: Date.now() + 300000,
  //     TON: balances.TON,
  //     tsTON: balances.tsTON,
  //     hTON:  balances.hTON,
  //     stTON:  balances.stTON,
  //   }) 
  // }
  // убрано после решения не кешировать данные по up
  //  else if (type === 'up') {
  //   localStorage.upBalances = JSON.stringify({
  //     expireDate: Date.now() + 300000,
  //     TON: balances.TON,
  //     tsTON: balances.tsTON,
  //     hTON:  balances.hTON,
  //     stTON:  balances.stTON,
  //   }) 
  // }
}

export function redirectPath() {
  const confirmPincode: number = Number(localStorage.getItem('confirm_pincode') ?? 0)
  const now = Date.now()
  
  if (
      now - confirmPincode > 60 * 60 * 24 * 1000 &&
      (localStorage.pincode ?? '').length == 4
  ) {
     return 'security'
  } else {
    return  'home'
  }
}
