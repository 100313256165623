export default [
    {
        path: 'payment/simple/:hash',
        name: 'payment-simple',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Input form',
        },
        component: () => import('@/views/protocol/pages/acquiring/Simple.vue'),
    },
    {
        path: 'payment/advanced/:hash',
        name: 'payment-advanced',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Input form',
        },
        component: () => import('@/views/protocol/pages/acquiring/Advanced.vue'),
    },
    {
        path: 'transactions',
        name: 'transactions',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/protocol/pages/acquiring/Transactions.vue'),
    },
]
