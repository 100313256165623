import User from '@/api/v2/user';
import Wallet from '@/api/v2/wallet';
import {
  TonClient
} from '@ton/ton';
import { toUserFriendlyAddress } from '@tonconnect/ui';
import { tonConnectUI } from './connect';

const mainnetClient = new TonClient({  endpoint: `https://toncenter.com/api/v2/jsonRPC`, apiKey: process.env.TON_CENTER_API_KEY });
const testnetClient = new TonClient({  endpoint: `https://testnet.toncenter.com/api/v2/jsonRPC`, apiKey: process.env.TESTNET_TON_CENTER_API_KEY });

export const client = localStorage.network == 'testnet' ? testnetClient : mainnetClient
export const gasFee = 0.01;

/**
 * Инстанс кошелька
 */
export function getWallet() {
  return tonConnectUI?.wallet ?? null;
}

/**
 * Адрес кошелька
 */
export function getAddress() {
  return toUserFriendlyAddress(tonConnectUI.wallet.account.address);
}

/**
 * Получить балансы: TON, Supply, DurovUSD
 */
export async function getBalances() {
  const wallet = await getWallet();

  if (!wallet?.account?.address) {
    console.log('Get balances local storage clear')
    //TODO вынести отдельно функцию логаута
    // const chatId = localStorage.chatId ?? 0
    // let network = localStorage.network
    // localStorage.clear()
    // localStorage.setItem('network', network)
    // localStorage.setItem('chatId', chatId)
    // this.$router.go()
    return
  }


  if (Date.now() > localStorage.expireDate && localStorage.user) {
    const wallets =  parseBalances((JSON.parse(localStorage.user)).wallet)
    return wallets
  } else {
    await Wallet.updateBalance()

    const wallets = parseBalances((await User.login()).wallet)
    return wallets
  }
}


function parseBalances(wallet) {
  try {
    const updatedBalances = {
      TON: Number(wallet.find((el) => el.currency.ticker === 'TON')?.balance ?? 0),
      tsTON: Number(wallet.find((el) => el.currency.ticker === 'tsTON')?.balance ?? 0),
      hTON: Number(wallet.find((el) => el.currency.ticker === 'hTON')?.balance ?? 0),
      stTON: Number(wallet.find((el) => el.currency.ticker === 'stTON')?.balance ?? 0),
      DurovUSD: 0,// Number(fromNano(durovUSDBalance)),
    };
    return updatedBalances
  } catch(e) {
    return    {
      TON: 10,
      tsTON:  10,
      hTON: 10,
      stTON:  10,
      DurovUSD:  10,
    };
    // try {
    //   if (localStorage.user) {
    //     return parseBalances((JSON.parse(localStorage.user)).wallet)
    //   }
    // } catch(e) {
    //   return    {
    //     TON: 10,
    //     tsTON:  10,
    //     hTON: 10,
    //     stTON:  10,
    //     DurovUSD:  10,
    //   };

    // }


  }

}
export function shortAddress(address, symbols = 2) {
  if (address?.length > 6) {
    return (
      address.substring(0, symbols) +
      '...' +
      address.substring(address.length - symbols, address.length)
    );
  }
}



/**
 * Получить список транзакций
 */
export async function getTransactions(limit = 30) {
  return [];
//   const wallet = await getWallet();
 
//   const axiosInstance = axios.create({
//     withCredentials: false,
// })
//   try{
//     const resp = await axiosInstance.post(endpoint, {
//         "id": "1", 
//         "jsonrpc": "2.0", 
//         "method": "getTransactions",
//         "archival": true,
//         "params": {
//             "address": wallet.account.address, 
//             "limit": limit
//         }
//     })


//     if (resp.status != 200 || !resp.data.ok) {
//       return []

//     } else {

//       const filteredResult = resp.data.result.filter((transaction) => {
//         return transaction.in_msg.message.includes('Durov')
//       })

//       return filteredResult
      
//     }
//   } catch(err){
//     console.log(err)
//     return [];
//   }

}

/**
 * Получить данные о валюте контракта
 */
export async function getContractState(address) {
  return await client.getContractState(address);
}
