import Wallet from '@/api/v2/wallet'

import { mnemonicNew, mnemonicToPrivateKey } from "@ton/crypto";
import TonWeb from "tonweb";

import showSwal from '@/libs/sweet-alerts.js'
import { clearUserInfo } from '@/libs/ton/utils'

import router from '@/router'
import { THEME, TonConnectUI, toUserFriendlyAddress } from '@tonconnect/ui'

export const tonweb = new TonWeb(
    new TonWeb.HttpProvider('https://' + (localStorage.network == 'testnet' ? 'testnet.' : '' ) + 'toncenter.com/api/v2/jsonRPC', {
        apiKey: localStorage.network == 'testnet' ? process.env.TESTNET_TON_CENTER_API_KEY :  process.env.TON_CENTER_API_KEY,
    })
);
/**
 * Инстанс
 */
export const tonConnectUI = new TonConnectUI({
    manifestUrl: 'https://app.durovs-protocol.com/tonconnect-manifest.json',
    twaReturnUrl: 'https://t.me/durovs_protocol_bot',
    uiPreferences: { theme: THEME.DARK },
})

/**
 * Отключение
 */
export async function disconnectWallet() {
    // TODO: подтверждение логаута
    if(localStorage.getItem('mnemonics')){
        localStorage.removeItem('mnemonics')
        localStorage.removeItem('address')
    } else {
        await tonConnectUI.disconnect()
    }
    clearUserInfo()
    router.go()
}

/**
 * Подключение
 */
export async function connect() {
    try {
        const status = tonConnectUI.connected
        if (status) {
            await tonConnectUI.disconnect()
        }
        await tonConnectUI.openModal()

        const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
            if (wallet) {
                // await updateUserWallets(wallet)

                unsubscribe()

                await Wallet.connect(wallet.account.address).then(
                    async (res) => {console.log('Update wallet on: ' + wallet.account.address)}
                )
                router.go()
            } else {
                clearUserInfo()
            }
        })
    } catch (e) {
        console.log(e)
    }
}

/**
 *
 * Статус подключения
 */
export function isConnected() {
    if (localStorage.getItem('mnemonics')) {
        return true
    }

    const connected = tonConnectUI.connected

    if (!connected) {
        clearUserInfo()
    }

    return connected
}

/**
 *
 * Получение подписи
 */
export function getSenderTonConnect() {
    return {
        sender: {
            send: async (args) => {

                // if (localStorage.getItem('mnemonics')) {
                    // let tonweb = new Tonweb()
                    // let keyPair = mnemonicToPrivateKey(localStorage.getItem('mnemonics'));
                    // const wallet = tonweb.wallet.create({publicKey});
                // }

                // try {
                tonConnectUI
                    .sendTransaction({
                        messages: [
                            {
                                address: args.to.toString(),
                                amount: args.value.toString(),
                                payload: args.body?.toBoc().toString('base64'),
                            },
                        ],
                        validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
                    })
                    .then((res) => {
                        showSwal({
                            text: 'Request sent to the blockchain, pending',
                            icon: 'success',
                        })

                        if (!args.disableRedirect) {
                            router
                                .push({ name: args.redirectPage ?? 'home' })
                                .then(async (res) => {
                                    try {
                                        await Wallet.updateBalance()
                                    } catch (e) {
                                        console.log(e)
                                    }
                                })
                        }
                    })
                    .catch((err) => {
                        showSwal({
                            text: 'Sending error',
                            icon: 'error',
                        })
                        router.push({ name: 'home' })
                    })
            },
        },
    }
}

export async function create(){
    // if(localStorage.getItem('mnemonics')){
    //     return;
    // }

    console.log('Creating wallet...')

    let mnemonics = await mnemonicNew();
    localStorage.setItem('mnemonics', mnemonics);

    let keyPair = await mnemonicToPrivateKey(mnemonics)

    const WalletClass = tonweb.wallet.all['v4R2'];
    const wallet = new WalletClass(tonweb.provider, {
        publicKey: keyPair.publicKey,
        wc: 0
    });

    const address = (await wallet.getAddress()).toString();
    localStorage.setItem('address', toUserFriendlyAddress(address));
}