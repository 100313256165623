import home from "./home";
import wallet from "./wallet";

export default [
    ...home, 
    ...wallet,
    {
        path: 'invites',
        name: 'invites',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/protocol/Invites.vue'),
    },
    {
        path: 'partners',
        name: 'partners',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/protocol/Partners.vue'),
    }
]
