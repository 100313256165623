import Swal from 'sweetalert2'
const options = {
    confirmButtonColor: '#A94CD8',
    cancelButtonColor: '#ea5455',
    width: 250,
}

const showSwal = (content) => Swal.fire(Object.assign(content, options))

export default showSwal
