import axiosIns from '@/libs/axios'

export default class User {
    static async login(chatId = '') {
        if (chatId == '') {
            chatId = localStorage.getItem('chatId')
        }
        let resp = await axiosIns.get(`/user/get-by-chat-id`, {
            params: {
                chat_id: chatId,
            },
        })
        localStorage.user = JSON.stringify(resp.data.user)
        User.checkTasks(resp.data.user.tasks)
        return resp.data
    }

    static checkTasks(tasks) {
        let hasUnclaimedTask = false
        for (const task of tasks) {
            if (task.status == 'claim') {
                hasUnclaimedTask = true
                break
            }
        }
        localStorage.setItem('hasUnclaimedTask', hasUnclaimedTask)
    }
}
