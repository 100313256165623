export const initialAbility = process.env.NODE_ENV === 'mainnet' ? [
    {
        action: 'manage',
        subject: 'CommonPages',
    },
    {
        action: 'manage',
        subject: 'Lk',
    },
    // {
    //     action: 'manage',
    //     subject: 'AutorizedLk',
    // }
    // {
    //     action: 'manage',
    //     subject: 'Admin',
    // },
    // {
    //     action: 'manage',
    //     subject: 'AutorizedLk',
    // }
] :
    (process.env.DEV_MENU === true ? [
        {
            action: 'manage',
            subject: 'CommonPages',
        },
        {
            action: 'manage',
            subject: 'Lk',
        },
        {
            action: 'manage',
            subject: 'Dev',
        }
    ] : [
        {
            action: 'manage',
            subject: 'CommonPages',
        },
        {
            action: 'manage',
            subject: 'Lk',
        },
    ])
export const _ = undefined
