import Vue from 'vue'
import Vuex from 'vuex'

// Vuexy
import app from './app'
import appConfig from './app/app-config'
import verticalMenu from './app/vertical-menu'
// Modules
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        // modules
        user,
    },
    strict: false,
})
