import Vue from 'vue'
import i18n from '@/libs/i18n'
import PortalVue from 'portal-vue'

import App from './App.vue'
import router from './router'
import store from './store'
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@axios'

// Connect fonts
import '@core/assets/fonts/ibm_plex_mono/iconfont.css'
import '@core/assets/fonts/montserrat/iconfont.css'
import '@core/assets/fonts/rubik_wet_paint/iconfont.css'

import '@core/scss/core.scss'

Vue.use(PortalVue)
// Vue.use(VueReCaptcha, {
//     siteKey: process.env.RECAPCHA_SITE_KEY,
//     loaderOptions: {
//         autoHideBadge: true,
//     },
// })
// Vue.use(VueCarousel)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
